import React, { useState, useRef, useEffect } from 'react'
import { Redirect } from 'react-router-dom';
import { app } from '../../base';
import logoImage from '../../styles/images/sqwad-hand.png';
import logoWithTextImageBlack from '../../styles/images/new_sqwad_logo.png';
import '../../styles/css/main.css';
import swal from 'sweetalert2';

const AdminLogin = ({ user }) => {
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const loginForm = useRef(null);

    const authWithEmailPassword = (event) => {
        event.preventDefault();
        setLoading(true);
        app
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then((providers) => {
                setLoading(false);
                // sign user in
                return app.auth().signInWithEmailAndPassword(email, password);
            }, this)
            .then((user) => {
                if (user) {
                    loginForm.current.reset();
                    setRedirect(true);
                } else {
                    swal({
                        title: 'Wrong credentials',
                        text: 'Check your email and password and try again!',
                        type: 'warning',
                        confirmButtonText: 'Ok',
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                swal({
                    title: 'Uh oh!',
                    text: error,
                    type: 'error',
                    confirmButtonText: 'Ok',
                });
            });
    };

    useEffect(() => {
        if (app.auth().currentUser) {
            setRedirect(true);
        }
        setLoading(false);
    }, []);

    if (redirect === true || user) {
        return <Redirect to="/admin" />;
    }

    return (
        <div className="auth-fluid">
            <div className="loading-screen" style={{ display: loading ? 'block' : 'none' }} />
            <div className="auth-fluid-form-box">
                <div className="align-items-center d-flex h-100">
                    <div className="card-body">

                        <div className="auth-brand text-center text-lg-left" style={{ marginBottom: '50px' }}>
                            <img src={logoWithTextImageBlack} alt="" height="auto" width="200px" />
                        </div>

                        <h4 className="mt-0" style={{ fontWeight: 'bolder', fontFamily: 'Roboto' }}>Welcome</h4>
                        <p className="text-muted2 mb-4" style={{ fontWeight: 'bold', fontFamily: 'Roboto' }}>Enter your email address and password to access account.</p>

                        <form onSubmit={authWithEmailPassword} ref={loginForm} style={{ fontWeight: 'bold', fontFamily: 'Roboto' }}>
                            <div className="form-group">
                                <label htmlFor="emailaddress">Email address</label>
                                <input className="form-control" type="email" name="email" placeholder="Enter your email" onChange={(e)=>setEmail(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input className="form-control" name="password" type="password" placeholder="Enter your password" onChange={(e)=>setPassword(e.target.value)} />
                            </div>
                            <div className="form-group mb-0 text-center">
                                <button className="btn btn-primary btn-block" type="submit" style={{ backgroundColor: "#ff1f3e", borderColor: "#ff1f3e", fontWeight: 'bold', fontFamily: 'Roboto' }}>Log In </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <div className="auth-fluid-right text-center">
                <div className="auth-user-testimonial">
                    <h2 className="mb-3" style={{ fontWeight: 'bolder', fontFamily: 'Roboto' }}>Memory Tip Of The Month </h2>
                    <p className="lead" style={{ fontWeight: 'bold', fontFamily: 'Roboto' }}>Plug the emails collected into Facebook, Instagram, & Snapchat ads for ticketing retargeting (5x-8x returns)
                    </p>
                    <img src={logoImage} width="200px" alt="" />
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;
