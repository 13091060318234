import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Loading from './components/utils/Loading';
import AdminHome from './components/admin/AdminHome'
import AdminLogin from './components/admin/AdminLogin'
import SetUpRewards from './components/admin/SetUpRewards';
import SetUpGame from './components/admin/SetUpGame';
import SetUpBrandingVariables from './components/admin/SetUpBrandingVariables';
import SetUpRulesAndRegs from './components/admin/SetUpRulesAndRegs';
import SetUpEmail from './components/admin/SetUpEmail';
import SetUpMatches from './components/admin/SetUpMatches';
import LoginVariables from './components/admin/SetUpLoginVariables';
import LeaderboardScoreboard from './components/scoreboard/Leaderboard';
import SetUpScoreboard from './components/admin/SetUpScoreboard';
import { app} from './base'

const AuthenticatedAdminRoute = ({ component: Component, authenticated, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (authenticated === true) {
                    return (
                        <Component {...props} {...rest} />
                    );
                } else {
                    return (
                        <Redirect to={{ pathname: '/adminLogin', state: { from: props.location } }} />
                    );
                }
            }}
        />
    );
};

class App extends Component {
    constructor(props) {
        super(props);
        this.setCurrentUser = this.setCurrentUser.bind(this);
        this.state = {
            authenticated: false,
            currentUser: null,
            loading: true,
        }
    }

    setCurrentUser(user) {
        if (user) {
            this.setState({
                currentUser: user,
                authenticated: true
            })
        } else {
            this.setState({
                currentUser: null,
                authenticated: false
            })
        }
    }

    componentDidMount() {
        this.removeAuthListener = app.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    authenticated: true,
                    currentUser: user,
                    loading: false,
                })
            } else {
                this.setState({
                    authenticated: false,
                    currentUser: null,
                    loading: false,
                })
            }
        })
    }

    componentWillUnmount() {
        this.removeAuthListener()
    }

    render() {
    if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading}/>
        )
    }
    return (
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/adminlogin"
              user={this.state.currentUser}
              render={(props) => {
                return <AdminLogin setCurrentUser={this.setCurrentUser} {...props} />
              }} />

            <AuthenticatedAdminRoute
                exact
                path="/admin"
                authenticated={this.state.authenticated}
                component={AdminHome} />

            <AuthenticatedAdminRoute
                exact
                path="/setupmatches"
                authenticated={this.state.authenticated}
                component={SetUpMatches} />

          <AuthenticatedAdminRoute
              exact
              path="/setupgame"
              authenticated={this.state.authenticated}
              currentUser={this.state.currentUser}
              component={SetUpGame} />

            <AuthenticatedAdminRoute
                exact
                path="/setuptickets"
                authenticated={this.state.authenticated}
                component={SetUpRewards} />

            <AuthenticatedAdminRoute
                exact
                path="/setupteamvariables"
                authenticated={this.state.authenticated}
                component={SetUpBrandingVariables} />

            <AuthenticatedAdminRoute
                exact
                path="/setuprulesandregs"
                authenticated={this.state.authenticated}
                component={SetUpRulesAndRegs} />

            <AuthenticatedAdminRoute
                exact
                path="/setupticketemail"
                authenticated={this.state.authenticated}
                component={SetUpEmail} />

              <AuthenticatedAdminRoute
                  exact
                  path="/setuploginvariables"
                  authenticated={this.state.authenticated}
                  component={LoginVariables} />

              <AuthenticatedAdminRoute
                  exact
                  path="/setupscoreboard"
                  authenticated={this.state.authenticated}
                  component={SetUpScoreboard} />

              <Route
                  exact
                  path="/leaderboard"
                  authenticated={this.state.authenticated}
                  component={LeaderboardScoreboard} />

            <Redirect to="/adminlogin"/>
          </Switch>
        </BrowserRouter>
    );
  }
}

export default App;
